<template>
    <ContentView>
        404
    </ContentView>
</template>

<script>
import ContentView from '@/components/ContentView.vue';

export default{
    name: "NotfoundView",
    components: { ContentView }
}
</script>

<style scoped>
</style>