//配置全局访问接口地址
// let remoteHttpsUrl = "http://localhost:3000";
let remoteHttpsUrl = "https://if9966.com";
// let remoteWssUrl = "ws://localhost:3000"
let remoteWssUrl = "wss://if9966.com";

let baseUrl = {
    remoteHttpsUrl,
    remoteWssUrl
}

export default baseUrl